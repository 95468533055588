import t from '../functions/t'

$('.js-toggle-packages').on('click', function(e) {
  e.preventDefault()
  let id = $(this).data('id')
  let type = $(this).data('type')
  $.ajax({
    url: '/consolidation_packages/',
    type: 'GET',
    dataType: 'script',
    data: {
      type,
      id,
    },
    success() {
      $(`#package-${id}`).toggle()
    },
  })
})

$('#shipping-method').on('click', '.js-insurance-warning', function() {
  if ($(this).data('warning')) {
    $('#uninsurable').removeClass('hide')
  } else {
    $('#uninsurable').addClass('hide')
  }
})

$('.js-select-shipping-method').click((e) => {
  e.preventDefault()
  const carrierNode = $("[name='shipping_method']:checked").parent()
  const perfume = $('#perfume').data('has-perfume')
  const country = $('#consolidation_info').data('country')
  let message = ''
  if (
    (carrierNode.data('carrier') == 'FedEx' ||
      carrierNode.data('carriermethod') ==
        'USPS Priority Mail Express International' ||
      carrierNode.data('carriermethod') ==
        'Asendia Express Mail International') &&
    country == 'BR'
  ) {
    message = t('batched_methods.content_high_customs_carrier_br')
  } else if (carrierNode.data('carriermethod') == 'FastBox Economy Post') {
    message = t('batched_methods.content_fastbox_economy')
  } else if (
    carrierNode.data('carriermethod') == 'USPS Priority Mail International'
  ) {
    message = t('batched_methods.content_priority_mail_tracking_change')
  } else {
    message = t('batched_methods.content')
  }
  if (perfume == 1) {
    message += t('errors.perfume_warning')
  }
  if (
    $.inArray(carrierNode.data('carrier'), [
      'Aramex',
      'AramexSB',
      'FastBox Express Korea',
    ]) > -1 ||
    (carrierNode.data('carrier') == 'FedEx' && country == 'BR') ||
    carrierNode.data('carriermethod') == 'FastBox Economy Post' ||
    carrierNode.data('carriermethod') == 'USPS Priority Mail International'
  ) {
    bootbox.confirm({
      message,
      buttons: {
        confirm: {
          label: t('batched_methods.confirm'),
        },
        cancel: {
          label: t('batched_methods.cancel'),
        },
      },
      callback(confirmed) {
        if (confirmed) {
          $('#method-form').submit()
        }
      },
    })
  } else {
    $('#method-form').submit()
  }
})

$(document).on('click', '#js-create-rma', function(e) {
  if ($(this).data('cart-items')) {
    e.preventDefault()
    e.stopPropagation()
    e.stopImmediatePropagation()

    var message = t('actions.confirm_return_alert')
    bootbox.confirm({
      message,
      buttons: {
        confirm: { label: t('actions.add_more_items') },
        cancel: { label: t('actions.current_item_only') },
      },
      callback(confirmed) {
        if (confirmed) {
          bootbox.alert({
            message: t('errors.messages.submit_current_parcel'),
          })
        } else {
          const itemId = $('#js-create-rma').data('item-id')
          $.ajax({
            url: `/items/${itemId}/consolidations/new`,
            type: 'GET',
            dataType: 'script',
          })
        }
      },
    })
  }
})

$(document).on('click', '#js-submit-rma', function(e) {
  e.preventDefault()
  e.stopPropagation()
  e.stopImmediatePropagation()

  var message = t('actions.confirm_return_alert')
  bootbox.confirm({
    message,
    buttons: {
      confirm: { label: t('actions.add_more_items') },
      cancel: { label: t('actions.current_item_only') },
    },
    callback(confirmed) {
      if (confirmed) {
        location.href = '/current_parcel?alert=true'
      } else {
        var input = $('<input>')
          .attr('type', 'hidden')
          .attr('name', 'request')
          .val(true)
        $('#new-rma').append(input)
        $('#new-rma').submit()
      }
    },
  })
})
