import { Controller } from '@hotwired/stimulus'

const loadingHtml = () => {
  return `
    <span class="badge badge-secondary js-loading-badge">
      <span class="spinning">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="mdi-loading" width="24" height="24" viewBox="0 0 24 24">
          <path d="M12,4V2C6.48,2 2,6.48 2,12H4C4,7.58 7.58,4 12,4Z"></path>
        </svg>
      </span>
      ${I18n[document.documentElement.lang].autosave.before}
    </span>
  `
}

const successHtml = () => {
  return `
    <span class="badge badge-success">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="mdi-check" width="24" height="24" viewBox="0 0 24 24">
          <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"></path>
        </svg>
      </span>
      ${I18n[document.documentElement.lang].autosave.success}
    </span>
  `
}

const errorHtml = () => {
  return `
    <span class="badge badge-danger js-error-badge">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="mdi-skull" width="24" height="24" viewBox="0 0 24 24">
          <path d="M12,2C7.03,2 3,6.03 3,11C3,14.03 4.53,16.82 7,18.47V22H9V19H11V22H13V19H15V22H17V18.46C19.47,16.81 21,14 21,11C21,6.03 16.97,2 12,2M8,11C9.1,11 10,11.9 10,13C10,14.1 9.1,15 8,15C6.9,15 6,14.1 6,13C6,11.9 6.9,11 8,11M16,11C17.1,11 18,11.9 18,13C18,14.1 17.1,15 16,15C14.9,15 14,14.1 14,13C14,11.9 14.9,11 16,11M12,14L13.5,17H10.5L12,14Z"></path>
        </svg>
      </span>
      ${I18n[document.documentElement.lang].autosave.error}
    </span>
  `
}

export default class extends Controller {
  static targets = ['feedbacker']

  connect() {
    // Display the Saved badge when a CD form has been created/updated
    if (this.element.dataset.reloadedForm === 'reloaded') {
      this.element.removeAttribute('data-reloaded-form')
      this.success()
    }
  }

  loading() {
    this.feedbackerTarget.innerHTML = loadingHtml()
  }

  success() {
    this.feedbackerTarget.innerHTML = successHtml()
  }

  error(event) {
    this.feedbackerTarget.innerHTML = errorHtml()
  }
}
