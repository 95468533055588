import { Controller } from '@hotwired/stimulus'

const filterCheckedRows = (rows) => {
  return [...rows].filter(
    (row) => row.querySelector('input[data-checkbox]').checked
  )
}

const recalculateTotalWeight = (rows) => {
  const totalWeight = filterCheckedRows(rows).reduce((acc, row) => {
    const weight = row.querySelector('input[data-weight]').dataset.weight
    return acc + (Number(weight) || 0) // returns 0 if the first expression is NaN
  }, 0)

  return totalWeight.toFixed(2)
}
const setTotalWeight = (tag, totalWeight) => tag.replaceChildren(totalWeight)

const obtainCheckedIds = (rows) => {
  return filterCheckedRows(rows)
    .map(
      (row) => row.querySelector('input[data-checkbox]').dataset.consolidationId
    )
    .filter(Number)
}

export default class extends Controller {
  static targets = [
    'consolidationRow',
    'totalWeight',
    'loadingStatus',
    'mpsDetails',
    'mpsDetailsForm',
    'mpsDetailsConsolidationIds',
  ]

  connect() {
    const newTotalWeight = recalculateTotalWeight(this.consolidationRowTargets)
    setTotalWeight(this.totalWeightTarget, newTotalWeight)
  }

  recalculateTotalWeight() {
    const newTotalWeight = recalculateTotalWeight(this.consolidationRowTargets)
    setTotalWeight(this.totalWeightTarget, newTotalWeight)
    this.clearMpsDetailsContent()
  }

  setConsolidationIds(event) {
    this.clearMpsDetailsContent()
    this.showLoadingStatus()

    const consolidation_ids = obtainCheckedIds(this.consolidationRowTargets)
    this.mpsDetailsConsolidationIdsTarget.value = consolidation_ids
  }

  clearMpsDetailsContent() {
    this.mpsDetailsTarget.replaceChildren()
  }

  showLoadingStatus() {
    this.loadingStatusTarget.replaceChildren('Loading...')
  }
}
