import { Controller } from '@hotwired/stimulus'
import t from '../functions/t'

const isNewLineVisible = (element) => {
  const elementContent = element.innerHTML
  return elementContent.trim() !== ''
}

export default class extends Controller {
  static targets = [
    'newCustomsDeclarationLine',
    'newCustomsDeclarationTurboFrame',
  ]
  static classes = ['animatedWarning']

  addNewLine(event) {
    if (!isNewLineVisible(this.newCustomsDeclarationTurboFrameTarget)) return

    event.preventDefault()

    this.newCustomsDeclarationLineTarget.classList.remove(
      ...this.animatedWarningClasses
    )
    // Since the browser optimizes the DOM updates, the 'remove' and 'add' lines of code have no effect,
    // I added a tiny timeout to force the browser to recognize the change
    setTimeout(() => {
      return this.newCustomsDeclarationLineTarget.classList.add(
        ...this.animatedWarningClasses
      )
    }, 100)
  }
}
