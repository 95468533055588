// When users arrive from mvb affiliate links, convert them to our own format
if (window.location.hash) {
  // Puts hash in variable, and removes the # character
  var hash = window.location.hash.substring(1)

  var token = hash.match(/afiliado=([^&]*)?/)

  if (token) {
    window.location.href = '/affiliate_link/' + token[1] + '/redirect'
  }
}
