const expectedTotalLabel = () => {
  if ($('#fast_buy_promo_code').val()) {
    switch ($('#fast_buy_promo_action').val()) {
      case '0':
        // ref: PROMO_PURCHASE_IF_INVALID = 0
        return $("label[for='fast_buy_expected_total']").text(
          $('#fast_buy_form').data('expected-without-promo')
        )
      case '1':
        // ref: PROMO_DONT_IF_INVALID = 1
        return $("label[for='fast_buy_expected_total']").text(
          $('#fast_buy_form').data('expected-with-promo')
        )
      case '2':
        // ref: PROMO_CONTACT_ME = 2
        return $("label[for='fast_buy_expected_total']").text(
          $('#fast_buy_form').data('expected-with-promo')
        )
      default:
        return $("label[for='fast_buy_expected_total']").text(
          $('#fast_buy_form').data('expected-total')
        )
    }
  } else {
    $("label[for='fast_buy_expected_total']").text(
      $('#fast_buy_form').data('expected-total')
    )
  }
}
$('#fast_buy_promo_code').on('change', () => {
  if ($('#fast_buy_promo_code').val()) {
    $('.fast_buy_promo_action').removeClass('hide')
    $('#fast_buy_promo_action').focus()
  } else {
    $('.fast_buy_promo_action').addClass('hide')
  }
  expectedTotalLabel()
})

if ($('#fast_buy_expected_total').data('uk')) {
  $('#gbp-to-usd-total').removeClass('hide')
  $('#gbp-to-usd-shipping').removeClass('hide')
}

$('#fast_buy_expected_total').on('change', () => {
  let amount = parseFloat($('#fast_buy_expected_total').val())
  let exchangeRate = $('#fast_buy_expected_total').data('rate')
  let convertedAmount = parseFloat((amount * exchangeRate).toFixed(2))
  let url = $('#fast_buy_form').data('url')
  let name = $('#fast_buy_form').data('name')
  let check_amount
  if ($('#fast_buy_expected_total').data('uk')) {
    check_amount = convertedAmount
  } else {
    check_amount = amount
  }
  if (isNaN(amount)) {
    amount = 0
    convertedAmount = 0
  }
  $('#gbp-to-usd-total-amount').text('$' + convertedAmount.toString())
  $('#fast_buy_expected_total').val(amount.toFixed(2))
  if (check_amount <= $('#fast_buy_expected_total').data('balance')) {
    $('#insufficient-funds').addClass('hide')
  } else {
    if ($('#fast_buy_expected_total').data('uk')) {
      $('#insufficient-amount').html(
        $('#fast_buy_expected_total')
          .data('message')
          .replace('n.nn', convertedAmount)
      )
    } else {
      $('#insufficient-amount').html(
        $('#fast_buy_expected_total')
          .data('message')
          .replace('n.nn', $('#fast_buy_expected_total').val())
      )
    }
    $('#insufficient-funds').removeClass('hide')
    check_blocked_store(url, name, get_facility_code())
  }
})

$('#fast_buy_shipping_allowance_amount').on('change', () => {
  let amount = parseFloat($('#fast_buy_shipping_allowance_amount').val())
  let exchangeRate = $('#fast_buy_expected_total').data('rate')
  let convertedAmount = (amount * exchangeRate).toFixed(2)
  if (isNaN(amount)) {
    amount = 0
    convertedAmount = 0
  }
  $('#fast_buy_shipping_allowance_amount').val(amount.toFixed(2))
  $('#gbp-to-usd-shipping-amount').text('$' + convertedAmount.toString())
})

$('#fast_buy_store_name').on('change', function() {
  const name = $(this).val()
  check_private_store(null, name, get_facility_code())
  check_blocked_store(null, name, get_facility_code())
})

$(document).on(
  'change',
  'input[id^=fast_buy_fast_buy_items_attributes_][id$=_full_url]',
  function() {
    var url = $(this).val()
    check_private_store(url, null, get_facility_code())
    check_blocked_store(url, null, get_facility_code())
  }
)

$('#fast_buy_promo_action').on('change', () => {
  expectedTotalLabel()
})

$('#js-empty-fast-buy-form').on('submit', (e) => {
  e.preventDefault()
  e.stopPropagation()
})

$('#js-empty-fast-buy-form').on('change', (e) => {
  const name = $('#js_fake_fast_buy_store_name').val()
  const url = $('#js_fake_fast_buy_full_url').val()
  check_blocked_store(url, name, get_facility_code())
})

function check_blocked_store(url = null, name = null, facility_code = null) {
  let element1 = $('.fast_buy_blocked_stores_message')
  let element3 = $('#insufficient-funds-fake')
  let element4 = $('#insufficient-funds')
  let element5 = $('#fastbuy_submit')
  let element6 = $('#fast_buy_blocked_stores_add_item')
  let element7 = $('#fast_buy_price_options')
  let amount = parseFloat($('#fast_buy_expected_total').val())
  if (url || name) {
    $.ajax({
      url: '/fast_buys/check_blocked_stores',
      data: { url: url, name: name, facility_code: facility_code },
      type: 'GET',
      success: function(data) {
        if (data) {
          element1.removeClass('hide')
          element3.addClass('hide')
          element4.addClass('hide')
          element5.addClass('hide')
          element6.addClass('hide')
          element7.addClass('hide')
        } else {
          element1.addClass('hide')
          element5.removeClass('hide')
          element6.removeClass('hide')
          element7.removeClass('hide')
          if (amount > $('#fast_buy_expected_total').data('balance')) {
            element3.removeClass('hide')
          }
        }
      },
    })
  }
}

function check_private_store(url = null, name = null, facility_code = null) {
  $.ajax({
    url: '/fast_buys/check_private_store',
    data: { url: url, name: name, facility_code: facility_code },
    type: 'GET',
    success: function(data) {
      var element = $('.fast_buy_private_address_message')
      if (data) {
        if (url) {
          element.addClass('url')
        } else {
          element.addClass('name')
        }
        element.removeClass('hide')
      } else {
        if (url) {
          element.removeClass('url')
        } else {
          element.removeClass('name')
        }
        if (!element.hasClass('name') && !element.hasClass('url')) {
          element.addClass('hide')
        }
      }
    },
  })
}

function get_facility_code() {
  var urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('facility_code')
}
