import React from 'react'
import { gql, useQuery } from '@apollo/client'
import t from '../functions/t'
import { Alert, Error, Loading } from '../components/standard'
import Result from '../components/Result'

const locale = document.documentElement.lang || 'en'

export const GET_RESULTS = gql`
  query($consolidation: ConsolidationInput!) {
    shippingQuote(consolidation: $consolidation) {
      messages
      # FIXME: covid19
      covidFeeApply
      shippingMethods {
        info {
          # FIXME: covid19
          covidFee {
            apply
            fee
          }
          company
          deliveryDays
          dimensionalWeight
          freqDep
          insurable
          insurance
          maxSize
          maxWeight
          name
          paragraph
          recommended
          requiresDutiesInAdvance
          systemName
          tracking
        }
        rate
      }
    }
  }
`

export default function Results({ consolidation = {} }) {
  const { loading, error, data } = useQuery(GET_RESULTS, {
    variables: { consolidation, locale },
  })

  if (!Object.keys(consolidation).length)
    return <Error message='No consolidation given' />
  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <div>
      {(consolidation.packages.depth === 0 ||
        consolidation.packages.height === 0 ||
        consolidation.packages.width === 0) && (
        <Alert intent='warning' message={t('messages.no_dimensions')} />
      )}
      {data.shippingQuote.messages.map((message, i) => (
        <Alert key={i} intent='info' message={message} />
      ))}
      {data.shippingQuote.shippingMethods.length === 0 && (
        <Alert intent='danger' message={t('errors.no_shipping_methods')} />
      )}
      {/* FixMe: RU Sanctions */}
      {consolidation.country === 'RU' && (
        <Alert
          intent='danger'
          message={
            <span>
              ВАЖНО! В данный момент мы не отправляем в Россию некоторые
              категории товаров. Подробнее{' '}
              <a href='https://www.fishisfast.com/ru/kb/shop_usa/shipping_methods/Sanctions'>
                здесь
              </a>
              .
            </span>
          }
        />
      )}
      {data.shippingQuote.shippingMethods.map(({ info, rate }) => (
        <Result
          country={consolidation.country}
          info={info}
          key={info.systemName}
          rate={rate}
        />
      ))}
    </div>
  )
}
