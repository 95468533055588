// Client Side Validations outputs as a sibling of the input field,
// when using a Bootstrap .input-group, this messes up the styling.
// This monkey patches the class to output outside of .input-group.

if (typeof ClientSideValidations !== 'undefined') {
  var errorClass = 'is-invalid'
  var errorBlockClass = 'invalid-feedback'

  var getErrorFields = function getErrorFields(el) {
    if (el.closest('.input-group')[0] != null) {
      return [el, el.closest('.input-group')]
    } else {
      return [el.parent(), el.parent()]
    }
  }

  ClientSideValidations.callbacks.element.fail = function(
    el,
    message,
    callback,
    eventData
  ) {
    if (!el.data('valid')) {
      var fields = getErrorFields(el)
      var $input = fields[0]
      var $inputGroup = fields[1]

      $input.addClass(errorClass)
      $inputGroup
        .parent()
        .find('.' + errorBlockClass)
        .remove()
      $inputGroup.after(
        "<div class='" + errorBlockClass + " d-block'>" + message + '</div>'
      )
    }

    return callback
  }

  ClientSideValidations.callbacks.element.pass = function(
    el,
    callback,
    eventData
  ) {
    var fields = getErrorFields(el)
    var $input = fields[0]
    var $inputGroup = fields[1]

    $input.removeClass(errorClass)
    $inputGroup
      .parent()
      .find('.' + errorBlockClass)
      .remove()
    return callback
  }
}
