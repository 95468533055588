import { Controller } from '@hotwired/stimulus'
import t from '../functions/t'

export default class extends Controller {
  stopIfLoading(event) {
    // Stop if CDs/CIs are still being saved
    if (
      document.querySelector('.item-details-and-customs-line .js-loading-badge')
    ) {
      event.preventDefault()
      bootbox.alert({ message: t('warnings.wait_until_customs_are_saved') })
    }
  }

  stopIfError(event) {
    if (
      !document.querySelector('.item-details-and-customs-line .js-error-badge')
    ) {
      return
    }
    event.preventDefault()

    // Stop if CDs/CIs contain errors
    if (
      document.querySelector('.item-details-and-customs-line .invalid-feedback')
    ) {
      bootbox.alert({ message: t('warnings.fill_in_required_fields') })
    } else {
      bootbox.alert({
        message: t('errors.unknown_error_when_updating_customs'),
      })
    }
  }
}
