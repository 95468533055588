import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'

export default function initImageGallery() {
  const lightbox = new PhotoSwipeLightbox({
    gallery: '.photoswipe',
    children: 'a',
    bgOpacity: 0.85,
    showAnimationDuration: 0, // To avoid blinking image when showing
    hideAnimationDuration: 0, // To avoid blinking image when hiding
    pswpModule: () => import('photoswipe'),
  })
  lightbox.init()
}
