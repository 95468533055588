import React from 'react'
import { mdiSkull } from '@mdi/js'
import t from '../../functions/t'
import Badge from './Badge'
import Icon from './Icon'

export default function Error({ message = t('autosave.error') }) {
  return (
    <Badge intent='danger'>
      <Icon path={mdiSkull} /> {message}
    </Badge>
  )
}
