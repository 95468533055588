import React from 'react'
import ReactSelect from 'react-select'
import { startCase } from 'lodash-es'
import Label from './Label'

export default function Select({
  className = '',
  disabled = false,
  displayFullContent = false,
  error = '',
  hint = '',
  label = '',
  name = '',
  onChange = (e) => console.log('No onChange', e),
  options = [],
  placeholder = '',
  required = false,
  value = '',
}) {
  // This turns the react-select returned data into an object mimicking a SyntheticEvent for Formik's handleChange to work with.
  const handleOnChange = (a, b) => {
    onChange({
      target: {
        type: 'select-one',
        name: b.name,
        id: b.name,
        value: a.value,
        checked: null,
        outerHTML: null,
        options: null,
        multiple: false,
      },
    })
  }

  return (
    <div className='mb-3'>
      <Label error={error} required={required}>
        {label !== '' ? label : name !== '' ? startCase(name) : ''}
      </Label>
      <ReactSelect
        className={`${className} mb-3`}
        id={`react-select-${name}`}
        instanceId={name}
        isDisabled={disabled}
        name={name}
        options={options}
        placeholder={placeholder}
        styles={{
          control: (provided) =>
            error
              ? { ...provided, background: '#f8fafc', borderColor: '#d14562' }
              : { ...provided, background: '#f8fafc' },
          singleValue: (provided) => {
            // If your options contain more than one line of text, display the full contents
            let stylesForLargerBox = { ...provided }
            if (displayFullContent) {
              delete stylesForLargerBox.transform
              delete stylesForLargerBox.top
              delete stylesForLargerBox.position
            }
            return stylesForLargerBox
          },
        }}
        value={options.find((obj) => obj.value === value)}
        onChange={handleOnChange}
      />
      {error !== '' ? (
        <small className='text-danger'>{error}</small>
      ) : (
        hint !== '' && <small>{hint}</small>
      )}
    </div>
  )
}
