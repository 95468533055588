$('#affiliate_home input[type=text]').on('click', function() {
  $(this).select()
})

$('#affiliate_home #affiliate_type').change(function() {
  $(this)
    .closest('form')
    .submit()
})

$('#affiliate_coupon').keyup(function() {
  if (this.value.length > 0) {
    $('#affiliate_coupon_button').attr(
      'value',
      $('#affiliate_coupon_button').data('create')
    )
  } else {
    $('#affiliate_coupon_button').attr(
      'value',
      $('#affiliate_coupon_button').data('generate')
    )
  }
})

$('#affiliate_transaction_search').on('click', '#clear_filters', (e) => {
  e.preventDefault()
  $('#affiliate_transaction_search input[type=text]').val('')
  $('.js-action-filters').click()
})

$("#affiliate_tab a[data-toggle='tab']").on('shown', ({ target }) => {
  const tab = target.getAttribute('href').substr(1)
  if (tab === 'settings') {
    $('.statistics_sidebar').hide()
    $('.settings_sidebar').show()
  } else {
    $('.settings_sidebar').hide()
    $('.statistics_sidebar').show()
  }
})
