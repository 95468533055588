// ISO 3166-1 alpha-2
export const COUNTRIES_THAT_NEED_POSTAL_AND_CITY = [
  'CO',
  'EC',
  'IL',
  'MK',
  'PL',
  'RO',
  'SK',
]
export const CITY_INPUT = 'city_input'
export const CITY_SELECT = 'city_select'
export const POSTAL_INPUT = 'postal_input'
