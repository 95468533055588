import { Controller } from '@hotwired/stimulus'

// Make this function private by placing outside of the controller's scope
const validateForm = (element) => {
  const $form = $(element)
  const validators = $form[0].ClientSideValidations.settings.validators
  const isValid = $form.isValid(validators)
  $form.validate()

  return isValid
}

// Disable form inputs to prevent creating duplicate records
// Avoid using the "disabled" attribute as any fields marked as "disabled" will not be send to the server
// https://github.com/fishisfast/Fishis-Fast/pull/13241#issuecomment-1696056742
const disableFields = (fields) => {
  fields.forEach((fld) => fld.setAttribute('readonly', true))
}
const enableFields = (fields) => {
  fields.forEach((fld) => fld.setAttribute('readonly', false))
}

export default class extends Controller {
  static targets = ['field']

  initialize() {
    $(this.form).enableClientSideValidations()

    //https://turbo.hotwired.dev/reference/events
    this.turboFrame.addEventListener('turbo:submit-start', (event) => {
      this.dispatch('loading')
    })

    this.turboFrame.addEventListener(
      'turbo:submit-end',
      ({ detail: { success, fetchResponse } }) => {
        if (success === undefined) {
          this.dispatch('loading')
        } else if (fetchResponse.redirected) {
          // While regular updates do not cause redirects, an expired user session can
          this.dispatch('error')
        } else if (success === true) {
          this.dispatch('success')
          enableFields(this.fieldTargets)
        } else {
          this.dispatch('error')
          enableFields(this.fieldTargets)
        }
      }
    )

    // Fetch request fails due to network errors rather than business logic errors
    this.turboFrame.addEventListener('turbo:fetch-request-error', () => {
      this.dispatch('error')
      enableFields(this.fieldTargets)
    })
  }

  connect() {
    // Validate element every time when the controller is connected to the DOM element
    const isValid = validateForm(this.form)
    if (!isValid) {
      this.dispatch('error')
    }
  }

  changeInput() {
    const isValid = validateForm(this.form)

    if (isValid) {
      disableFields(this.fieldTargets)
      Rails.fire(this.form, 'submit')
    } else {
      this.dispatch('error')
    }
  }

  removeNewLine(event) {
    event.preventDefault()
    this.turboFrame.replaceChildren()
  }

  get form() {
    return this.element.querySelector('form')
  }

  get turboFrame() {
    return this.element.closest('turbo-frame')
  }
}
