import React from 'react'
import { startCase } from 'lodash-es'
import Label from './Label'

export default function Input({
  append = '',
  className = '',
  disabled = false,
  error = '',
  hint = '',
  label = '',
  max = 70,
  min = 0,
  name = '',
  onChange = (e) => console.log('No onChange', e),
  placeholder = '',
  prepend = '',
  required = false,
  type = 'text',
  value = '',
}) {
  return (
    <div className='mb-3'>
      <Label error={error} required={required}>
        {label !== '' ? label : name !== '' ? startCase(name) : ''}
      </Label>
      <div className={`input-group ${error ? 'is-invalid' : ''}`}>
        {prepend !== '' && (
          <div className='input-group-prepend'>
            <span className='input-group-text'>{prepend}</span>
          </div>
        )}
        <input
          className={`${className} form-control`}
          disabled={disabled}
          max={max}
          min={min}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          type={type}
          value={value}
        />
        {append !== '' && (
          <div className='input-group-append'>
            <span className='input-group-text'>{append}</span>
          </div>
        )}
      </div>
      {error !== '' ? (
        <small className='text-danger'>{error}</small>
      ) : (
        hint !== '' && <small>{hint}</small>
      )}
    </div>
  )
}
