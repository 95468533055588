import React from 'react'
import { mdiLoading } from '@mdi/js'
import t from '../../functions/t'
import Badge from './Badge'
import Icon from './Icon'

export default function Loading({ message = t('loading') }) {
  return (
    <Badge intent='secondary'>
      <Icon path={mdiLoading} spinning /> {message}
    </Badge>
  )
}
