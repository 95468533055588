import { gql, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'

/**
 * GraphQL endpoint URL.
 * Always point the client to port 5000 for Storybook
 */
const uri =
  window.location.host === 'fishdev.me:6006'
    ? `http://fishdev.me:5000/graphql/client`
    : `/graphql/client`

/**
 * Document CSRF meta tag
 */
const tag = document.head.querySelector('[property~=csrf-token][content]')

/**
 * Get auth token from CSRF meta tag, default to ''
 */
const token = tag ? tag['content'] : ''

/**
 * Request headers for client.
 */
const headers = {
  'X-CSRF-Token': token,
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Accept-Language': document.documentElement.lang || 'en',
}

/**
 * Make sure devise cookies are sent along for auth
 */
const credentials = 'include'

/**
 * Initiate Apollo client
 */
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri,
    headers,
    credentials,
    includeUnusedVariables: true,
  }),
})

export default client
