import t from '../functions/t'

const indexOf =
  [].indexOf ||
  function(item) {
    for (let i = 0, l = this.length; i < l; i++) {
      if (i in this && this[i] === item) return i
    }
    return -1
  }

$('#save_user').click(() => {
  if (
    $('#registrations_handling_tab_form input[type="radio"]:checked').val() !==
    $('#current_handling').val()
  ) {
    bootbox.confirm({
      message: t('warnings.change_preference'),
      callback(confirmed) {
        if (confirmed) {
          $('#edit_user').submit()
        }
      },
    })
  } else {
    $('#edit_user').submit()
  }
})

$('#user_remember_me').attr('checked', 'true')

$('#id_search').change(function() {
  let search = `search[${$(this).val()}]`
  $('#id_numeric').attr('name', search)
})

$('.onchange_show_fields').change(function() {
  let country = $(this).val()
  let match = $(this).data('onchange-match')
  if (indexOf.call(match, country) >= 0) {
    $('.match_show').removeClass('hide')
    $('.match_hide').addClass('hide')
  } else {
    $('.match_hide').removeClass('hide')
    $('.match_show').addClass('hide')
  }
})

$('.onchange_show_fields').change(function() {
  let country = $(this).val()
  let idCountries = $(this).data('id-countries')
  if (idCountries.includes(country)) {
    $('.destination_address_customs_number').removeClass('hide')
  } else {
    $('.destination_address_customs_number').addClass('hide')
  }
  // ref: Boxberry::Points::COUNTRIES.keys
  if (country === 'RU') {
    let str = $('#js-boxberry-add-service-point').attr('href')
    if (str !== undefined) {
      let n = str.indexOf('country=')
      if (n === -1) {
        str = `${str}?country=${country}`
      } else {
        str = str.substr(0, n + 8) + country
      }
      $('#js-boxberry-add-service-point').attr('href', str)
      $('.js-boxberry').removeClass('hide')
    }
  } else {
    $('.js-boxberry').addClass('hide')
  }
  if (country === 'RU') {
    $('.js-passport').removeClass('hide')
  } else {
    $('.js-passport').addClass('hide')
  }
})

$('#boxberry').on('click', '.js-boxberry-service-point', function() {
  $('#boxberry').modal('hide')
  $.ajax({
    url: '/boxberry/update',
    data: {
      code: $(this).data('code'),
      country: $(this).data('country'),
    },
  })
  $('#destination_address_boxberry_code').val($(this).data('code'))
})

$('#user_broadcast_notification_popup').modal({
  backdrop: 'static',
  keyboard: false,
})

$('#revise_consolidation').click(function(e) {
  e.preventDefault()
  e.stopPropagation()
  $('#revise_popup_continue').attr('href', $(this).attr('href'))
  $('#revise_popup').show()
  $('#revise_popup').modal({
    backdrop: 'static',
    keyboard: false,
  })
  $('body').css('overflow', 'hidden')
})

$('.activate-help-contact').click(function(e) {
  e.preventDefault()
  if (window.fcWidget.isLoaded() == true) {
    window.fcWidget.open()
  } else {
    Intercom('show')
  }
})
