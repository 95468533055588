import { Controller } from '@hotwired/stimulus'

// This is a workaround to make Select2 work with Stimulus
// Select2 doesn't support native DOM events. So, it's necessary to turn the Select2 Change event into a native one for Stimulus
// New events can be added on-demand
export default class extends Controller {
  connect() {
    $(this.element).select2({
      width: 'calc(80% - 22px)',
    })

    $(this.element).on('select2:select', this.dispatchChangeEvent.bind(this))
  }

  // https://select2.org/programmatic-control/methods#event-unbinding
  disconnect() {
    $(this.element).select2('destroy')
    $(this.element).off('select2:select', this.dispatchChangeEvent.bind(this))
  }

  // Dispatch a native DOM event
  dispatchChangeEvent() {
    this.element.dispatchEvent(new Event('change'))
  }
}
