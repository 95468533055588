import React from 'react'

export default function Card({
  intent = '',
  flush = false,
  header = null,
  body = null,
  footer = null,
  style = {},
}) {
  return (
    <div
      className={`card ${intent !== '' &&
        `bg-${intent} ${
          intent === 'warning'
            ? 'text-warning'
            : intent === 'info' || intent === 'light'
            ? 'text-dark'
            : 'text-white'
        }`}`}
      style={{ overflow: 'initial', ...style }}
    >
      {header && <div className='card-header'>{header}</div>}
      {body && <div className={flush ? '' : 'card-body'}>{body}</div>}
      {footer && <div className='card-footer text-right'>{footer}</div>}
    </div>
  )
}
