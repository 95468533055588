import {
  COUNTRIES_THAT_NEED_POSTAL_AND_CITY,
  CITY_INPUT,
  POSTAL_INPUT,
  CITY_SELECT,
} from '../constants/CALC_SETTINGS'

export default function fieldsToShowInCalculator(
  code = 'XX',
  optionsCities = [],
  postalAware = false
) {
  let show = []
  const cities = optionsCities.length ? true : false

  if (cities) {
    show.push(CITY_SELECT)
  }
  if (
    (!cities && COUNTRIES_THAT_NEED_POSTAL_AND_CITY.includes(code)) ||
    (!cities && !postalAware)
  ) {
    show.push(CITY_INPUT)
  }
  if (
    (!cities && postalAware) ||
    (COUNTRIES_THAT_NEED_POSTAL_AND_CITY.includes(code) && postalAware)
  ) {
    show.push(POSTAL_INPUT)
  }

  return show
}
