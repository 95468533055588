import React from 'react'
import { mdiAlert } from '@mdi/js'
import Icon from './Icon'

export default function Alert({
  intent = 'danger',
  message = '',
  icon = mdiAlert,
  className = '',
  ...props
}) {
  return (
    <div {...props} className={`${className} alert alert-${intent}`}>
      <Icon path={icon} />
      {message}
    </div>
  )
}
