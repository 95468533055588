import React, { useState } from 'react'
import {
  mdiMenuUp,
  mdiMenuDown,
  mdiWeight,
  mdiTapeMeasure,
  mdiArrowExpandAll,
  mdiBinoculars,
  mdiCheck,
  mdiClose,
  mdiTruckFast,
  mdiPackageUp,
  mdiAlarm,
} from '@mdi/js'
import t from '../functions/t'
import getCarrierData from '../functions/getCarrierData'
import { Card, Col, Icon, Justify, Row, Error } from './standard'

export default function Result({
  rate = 0,
  country = '',
  info = {},
  openDefault = false,
}) {
  const [open, setOpen] = useState(openDefault)

  if (!Object.keys(info).length) return <Error message='No info given' />

  return (
    <Card
      style={{ opacity: rate === 0 ? 0.5 : 1 }}
      header={
        <div onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
          <Row>
            <Col xs={12} md={7}>
              <div
                style={{
                  display: 'inline-block',
                  height: '1em',
                  lineHeight: '1em',
                  marginRight: '0.5em',
                  width: '3em',
                }}
              >
                {getCarrierData(info.company).logo()}
              </div>
              <b>{info.name}</b>
            </Col>
            <Col xs={12} md={5}>
              <Justify>
                <span
                  className={
                    info.recommended ? 'text-success' : 'text-secondary'
                  }
                >
                  {info.deliveryDays}
                </span>
                <div>
                  <b>${rate === 0 ? '—' : rate.toFixed(2)}</b>
                  {open ? (
                    <Icon path={mdiMenuUp} large />
                  ) : (
                    <Icon path={mdiMenuDown} large />
                  )}
                </div>
              </Justify>
            </Col>
          </Row>
        </div>
      }
      body={
        open && (
          <div>
            <p>{info.paragraph}</p>
            {info.requiresDutiesInAdvance && (
              <p>{t('warnings.requires_duties_in_advance')}</p>
            )}
            <hr />
            <Row>
              <Col sm={4}>
                <b>
                  <Icon path={mdiAlarm} />
                  {t('shipping_methods.delivery_time')}
                </b>
              </Col>
              <Col sm={8} className='text-sm-right'>
                <span>{info.deliveryDays}</span>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={4}>
                <b>
                  <Icon path={mdiWeight} />
                  {t('shipping_methods.max_weight')}
                </b>
              </Col>
              <Col sm={8} className='text-sm-right'>
                <span>{info.maxWeight}</span>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={4}>
                <b>
                  <Icon path={mdiTapeMeasure} />
                  {t('shipping_methods.dimensional_weight')}
                </b>
              </Col>
              <Col sm={8} className='text-sm-right'>
                <span>{info.dimensionalWeight}</span>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={4}>
                <b>
                  <Icon path={mdiArrowExpandAll} />
                  {t('shipping_methods.max_size')}
                </b>
              </Col>
              <Col sm={8} className='text-sm-right'>
                <span>{info.maxSize}</span>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={4}>
                <b>
                  <Icon path={mdiBinoculars} />
                  {t('shipping_methods.tracking')}
                </b>
              </Col>
              <Col sm={8} className='text-sm-right'>
                {info.tracking === 'true' ? (
                  <Icon path={mdiCheck} className='text-success' />
                ) : info.tracking === 'false' ? (
                  <Icon path={mdiClose} className='text-danger' />
                ) : (
                  <span>{info.tracking}</span>
                )}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={4}>
                <b>
                  <Icon path={mdiTruckFast} />
                  {t('shipping_methods.freq_dep')}
                </b>
              </Col>
              <Col sm={8} className='text-sm-right'>
                <span>{info.freqDep}</span>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={4}>
                <b>
                  <Icon path={mdiPackageUp} />
                  {t('shipping_methods.insurance')}
                </b>
              </Col>
              <Col sm={8} className='text-sm-right'>
                <span>
                  {info.insurance}
                  <br />
                  {info.insurable && (
                    <span>
                      {t('shipping_methods.insurance_print').replace(
                        '%{country}',
                        country
                      )}
                    </span>
                  )}
                </span>
              </Col>
            </Row>
          </div>
        )
      }
    />
  )
}
