import React, { useState, useEffect } from 'react'
import { gql, useQuery, useLazyQuery } from '@apollo/client'
import { mdiCalculator } from '@mdi/js'
import { useFormik } from 'formik'
import convert from 'convert-units'
import { validate as validatePostalCode } from 'postal-codes-js'
import {
  CITY_INPUT,
  CITY_SELECT,
  POSTAL_INPUT,
} from '../constants/CALC_SETTINGS'
import t from '../functions/t'
import fieldsToShowInCalculator from '../functions/fieldsToShowInCalculator'
import validate from '../functions/validate'
import {
  Card,
  Col,
  Error,
  Grid,
  Icon,
  Justify,
  Loading,
  Row,
} from '../components/standard'
import { Input, Select, Submit } from '../components/form'
import Results from './Results'

const locale = document.documentElement.lang || 'en'
const urlParams = new URLSearchParams(window.location.search)

export const GET_COUNTRIES_QUERY = gql`
  {
    countries {
      code
      name
      flag
      postalAware
    }
  }
`

export const GET_CITIES_QUERY = gql`
  query($country: String!) {
    shipperCities(country: $country) {
      id
      name
    }
  }
`

export default function Calculator({
  initialValues = {
    country: '',
    city: '',
    cityId: '',
    postalCode: '',
    unit: 'us',
    weight: '',
    width: '',
    depth: '',
    height: '',
    facilityCode: 1,
  },
}) {
  const [optionsCountries, setOptionsCountries] = useState([])
  const [optionsCities, setOptionsCities] = useState([])
  const [consolidation, setConsolidation] = useState({})
  const [fieldsToShow, setFieldsToShow] = useState([CITY_INPUT])

  // Queries
  const { data, error, loading } = useQuery(GET_COUNTRIES_QUERY, {
    variables: { locale },
  })
  const [getCities, getCitiesResult] = useLazyQuery(GET_CITIES_QUERY)

  // Formik hook to manage the form values, validation and submit.
  const { values, errors, handleChange, handleSubmit, setValues } = useFormik({
    initialValues,
    validate: (values) => {
      // 👹 Due to dynamic conditionals, validations need to be rebuilt on each input change

      const VALIDATE_PRESENCE = {
        name: 'presence',
        options: [`{"message":"${t('errors.messages.blank')}"}`],
      }

      const VALIDATE_NUMERICALITY = {
        name: 'numericality',
        options: [
          `{"messages":{"numericality":"${t(
            'errors.messages.not_a_number'
          )}","greater_than_or_equal_to":"${t(
            'errors.messages.greater_than_or_equal_to'
          ).replace(
            '%{count}',
            '0.01'
          )}"},"allow_blank":true,"greater_than_or_equal_to":0.01}`,
        ],
      }

      let validations = [
        {
          attribute: 'country',
          validators: [VALIDATE_PRESENCE],
        },
        {
          attribute: 'weight',
          validators: [VALIDATE_PRESENCE, VALIDATE_NUMERICALITY],
        },
      ]

      // If a country has been selected, require postal or city, or both, based on the country
      if (fieldsToShow.includes(CITY_SELECT)) {
        validations.push({
          attribute: 'cityId',
          validators: [VALIDATE_PRESENCE],
        })
      }
      if (fieldsToShow.includes(POSTAL_INPUT)) {
        validations.push({
          attribute: 'postalCode',
          validators: [VALIDATE_PRESENCE],
        })
      }
      if (fieldsToShow.includes(CITY_INPUT)) {
        validations.push({
          attribute: 'city',
          validators: [VALIDATE_PRESENCE],
        })
      }

      // If a Width, Depth, or Height is given, require all three
      if (values.width !== '' || values.depth !== '' || values.height !== '') {
        validations.push({
          attribute: 'width',
          validators: [VALIDATE_PRESENCE, VALIDATE_NUMERICALITY],
        })
        validations.push({
          attribute: 'depth',
          validators: [VALIDATE_PRESENCE, VALIDATE_NUMERICALITY],
        })
        validations.push({
          attribute: 'height',
          validators: [VALIDATE_PRESENCE, VALIDATE_NUMERICALITY],
        })
      }

      // run standard validations
      let validationResults = validate(values, validations)

      // run special postal code validation
      if (fieldsToShow.includes(POSTAL_INPUT)) {
        let postalValidation = validatePostalCode(
          values.country,
          values.postalCode
        )
        if (typeof postalValidation === 'string') {
          let countryName = optionsCountries.filter(
            (obj) => obj.value === values.country
          )[0].label
          validationResults.postalCode = t('errors.postal_not_valid').replace(
            '%{country}',
            countryName
          )
        }
      }

      // return results
      return validationResults
    },
    onSubmit: (values) => {
      // Remove existing consolidation so Results disappear
      setConsolidation({})

      // API expects this object format to get results
      let newConsolidation = {
        country: values.country,
        postalCode: values.postalCode,
        facilityCode: values.facilityCode,
        packages: {
          depth: parseFloat(values.depth) || 0,
          height: parseFloat(values.height) || 0,
          weight: parseFloat(values.weight) || 0,
          width: parseFloat(values.width) || 0,
        },
      }
      if (fieldsToShow.includes(CITY_INPUT)) {
        newConsolidation.city = values.city
      } else if (fieldsToShow.includes(CITY_SELECT)) {
        newConsolidation.cityId = values.cityId
      }

      // Convert SI to rods and stones
      if (values.unit === 'si') {
        newConsolidation.packages.depth = convert(
          newConsolidation.packages.depth
        )
          .from('cm')
          .to('in')
        newConsolidation.packages.height = convert(
          newConsolidation.packages.height
        )
          .from('cm')
          .to('in')
        newConsolidation.packages.weight = convert(
          newConsolidation.packages.weight
        )
          .from('kg')
          .to('lb')
        newConsolidation.packages.width = convert(
          newConsolidation.packages.width
        )
          .from('cm')
          .to('in')
      }

      setConsolidation(newConsolidation)
    },
  })

  // Construct array for country select dropdown
  useEffect(() => {
    if (data) {
      const newOptionsCountries = data.countries
        .map(({ code, name, flag, postalAware }) => ({
          value: code,
          label: `${name} ${flag}`,
          postalAware: postalAware,
        }))
        .sort((a, b) => a.label > b.label)
      setOptionsCountries(newOptionsCountries)
    }
  }, [data])

  // When Country changes
  useEffect(() => {
    if (values.country) {
      // Get cities list for country
      getCities({ variables: { country: values.country, locale } })

      // Ensure to clear colums related to the address
      setValues({
        ...values,
        city: '',
        cityId: '',
        postalCode: '',
      })
    }
  }, [values.country, getCities])

  // When cities are loaded
  useEffect(() => {
    if (getCitiesResult.data) {
      // Get full country object and save in state (since getCitiesResult.variables.country is a string)
      const optionsCountry = optionsCountries.find(
        (obj) => obj.value === getCitiesResult.variables.country
      )

      // Construct array for city select dropdown
      const newOptionsCities = getCitiesResult.data.shipperCities.map(
        (shipperCity) => ({
          value: String(shipperCity.id), // Stringify the ID because graphql expects to see String value
          label: shipperCity.name,
        })
      )
      setOptionsCities(newOptionsCities)

      // Fields to show in form for this country
      const newFieldsToShow = fieldsToShowInCalculator(
        optionsCountry.value,
        newOptionsCities,
        optionsCountry.postalAware
      )
      setFieldsToShow(newFieldsToShow)
    }
  }, [getCitiesResult, optionsCountries])

  return (
    <Row>
      <Col sm={6} md={5} lg={4}>
        <Card
          header={
            <Justify>
              <b>
                <Icon path={mdiCalculator} />
                {t('navigation.shipping_calculator')}
              </b>
              {loading && <Loading />}
              {error && <Error />}
            </Justify>
          }
          body={
            <form onSubmit={handleSubmit}>
              <fieldset disabled={loading || error}>
                <Select
                  required
                  name='country'
                  value={values.country}
                  error={errors.country}
                  options={optionsCountries}
                  onChange={handleChange}
                  label={t('activerecord.attributes.consolidation.country')}
                  placeholder={t('shipping_calculators.form.select_country')}
                />
                {fieldsToShow.includes(CITY_SELECT) && (
                  <Select
                    required
                    name='cityId'
                    value={values.cityId}
                    error={errors.cityId}
                    options={optionsCities}
                    onChange={handleChange}
                    label={t('activerecord.attributes.consolidation.city')}
                  />
                )}
                {fieldsToShow.includes(POSTAL_INPUT) && (
                  <Input
                    required
                    name='postalCode'
                    value={values.postalCode}
                    error={errors.postalCode}
                    onChange={handleChange}
                    label={t(
                      'activerecord.attributes.consolidation.postal_code'
                    )}
                  />
                )}
                {fieldsToShow.includes(CITY_INPUT) && (
                  <Input
                    required
                    name='city'
                    value={values.city}
                    error={errors.city}
                    onChange={handleChange}
                    label={t('activerecord.attributes.consolidation.city')}
                    placeholder={t(
                      'shipping_calculators.form.english_letters_only'
                    )}
                  />
                )}
                <Select
                  required
                  name='unit'
                  value={values.unit}
                  error={errors.unit}
                  options={[
                    {
                      value: 'us',
                      label: t('metric_or_imperial.imperial'),
                    },
                    {
                      value: 'si',
                      label: t('metric_or_imperial.metric'),
                    },
                  ]}
                  onChange={handleChange}
                  label={t('metric_or_imperial.units')}
                />
                <Input
                  required
                  name='weight'
                  value={values.weight}
                  error={errors.weight}
                  onChange={handleChange}
                  label={t('activerecord.attributes.consolidation.weight')}
                  append={
                    values.unit === 'si'
                      ? t('measure.kg.other')
                      : t('measure.lbs.other')
                  }
                />
                <Input
                  name='width'
                  value={values.width}
                  error={errors.width}
                  onChange={handleChange}
                  label={t('activerecord.attributes.consolidation.width')}
                  append={
                    values.unit === 'si'
                      ? t('measure.cm.other')
                      : t('measure.in.other')
                  }
                />
                <Input
                  name='depth'
                  value={values.depth}
                  error={errors.depth}
                  onChange={handleChange}
                  label={t('activerecord.attributes.consolidation.depth')}
                  append={
                    values.unit === 'si'
                      ? t('measure.cm.other')
                      : t('measure.in.other')
                  }
                />
                <Input
                  name='height'
                  value={values.height}
                  error={errors.height}
                  onChange={handleChange}
                  label={t('activerecord.attributes.consolidation.height')}
                  append={
                    values.unit === 'si'
                      ? t('measure.cm.other')
                      : t('measure.in.other')
                  }
                />
                {
                  <Select
                    required
                    name='facilityCode'
                    value={values.facilityCode}
                    error={errors.facilityCode}
                    // TODO: get facilities from server
                    options={[
                      {
                        value: 1,
                        label: t('shipping_calculators.form.facility_us'),
                      },
                      {
                        value: 3,
                        label: t('shipping_calculators.form.facility_uk'),
                      },
                    ]}
                    onChange={handleChange}
                    label={t('shipping_calculators.form.shipping_from')}
                  />
                }
                <hr />
                <Submit block value={t('actions.get_shipping_rates')} />
              </fieldset>
            </form>
          }
        />
      </Col>

      <Col sm={6} md={7} lg={8}>
        <br />
        {!!Object.keys(consolidation).length && (
          <Results consolidation={consolidation} />
        )}
      </Col>
    </Row>
  )
}
