// When someone clicks 'dont send any notifications in Settings, deselect all checkboxes'
$('#registrations_notification_tab_form #do_not_send_button').on(
  'click',
  (e) => {
    e.preventDefault()
    $('#registrations_notification_tab_form input[type=checkbox]').each(
      function() {
        $(this).prop('checked', false)
      }
    )
  }
)
