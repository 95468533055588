import React from 'react'

export default function Label({
  children = '',
  className = '',
  error = '',
  htmlFor = '',
  required = false,
}) {
  return (
    <label
      className={`${className} ${error ? 'text-danger' : ''}`}
      htmlFor={htmlFor}
    >
      {children} {required ? '*' : ''}
    </label>
  )
}
