import React from 'react'
import { CompactPicker } from 'react-color'

export default function ColorInput({ color = '#fcc400' }) {
  const [state, setState] = React.useState({
    pickerOpen: false,
    color: color,
  })

  return (
    <div style={{ width: '100%' }}>
      <div className='input-group'>
        <div
          className='input-group-prepend'
          onClick={() => setState({ ...state, pickerOpen: true })}
        >
          <span className='input-group-text'>
            <div
              style={{
                width: '1em',
                height: '1em',
                borderRadius: '0.2em',
                background: state.color,
              }}
            />
          </span>
        </div>
        <input
          className='string required form-control'
          type='text'
          name='item_tag[color]'
          id='item_tag_color'
          value={state.color}
          onChange={(e) => setState({ ...state, color: e.target.value })}
        />
      </div>
      {state.pickerOpen && (
        <div style={{ position: 'absolute', zIndex: 2 }}>
          <CompactPicker
            color={state.color}
            onChange={(e) => setState({ color: e.hex, pickerOpen: false })}
          />
        </div>
      )}
    </div>
  )
}
