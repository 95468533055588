import t from '../functions/t'

const handleNewSelection = () => {
  $('#service_request #user_comment').removeClass('hide')
}

if ($('#service_request #confirm').length > 0) {
  const textMax = $(
    "textarea[id='service_request_additional_user_comment']"
  ).attr('maxlength')
  $('#user_textarea_feedback').html(`${textMax} ${t('chars_remaining')}`)
  $('#service_request_user_comment').keyup(() => {
    let textLength
    let textRemaining
    textLength = $('#service_request_user_comment').val().length
    textRemaining = textMax - textLength
    $('#user_textarea_feedback').html(
      `${textRemaining} ${t('chars_remaining')}`
    )
  })
  handleNewSelection()
}
