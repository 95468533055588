import React from 'react'
import { mdiSkull } from '@mdi/js'

export default function Icon({
  path = mdiSkull,
  spinning = false,
  large = false,
  className = '',
}) {
  return (
    <span className={className}>
      <svg
        viewBox='0 0 21 21'
        className={`
          svg-icon
          ${spinning ? 'spinning' : ''}
          ${large ? 'large' : ''}
        `}
      >
        <path d={path} />
      </svg>
      &nbsp;
    </span>
  )
}
