import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['newUserFfid', 'newUserInfo']

  changeNewUserFfid() {
    if (this.newUserFfidTarget.value === '') {
      this.showEmptyStatus()
    }
  }

  fetchNewUser(event) {
    event.preventDefault()

    const value = this.newUserFfidTarget.value
    if (value === '') {
      alert("Enter the new user's FFID into the input field above")
    } else {
      this.showLoadingStatus()
      get(event.target.href, {
        query: { new_ffid: value },
        contentType: 'application/json',
        responseKind: 'turbo-stream',
      })
    }
  }

  showEmptyStatus() {
    this.newUserInfoTarget.replaceChildren()
  }

  showLoadingStatus() {
    this.newUserInfoTarget.replaceChildren('Loading...')
  }
}
