// Sets image dimensions for PhotoSwipe. We don't want to do this
// server-side because it would require us to download the images
// on the server, which would be slow.
var photoswipe_links = document.querySelectorAll('.photoswipe-img')

for (const img_link of photoswipe_links) {
  const img = new Image()
  img.onload = function() {
    img_link.dataset.pswpWidth = this.width
    img_link.dataset.pswpHeight = this.height
  }
  img.src = img_link.href
}
