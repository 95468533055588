import React from 'react'
import t from '../../functions/t'

export default function Submit({
  block = false,
  disabled = false,
  intent = 'success',
  value = t('actions.save'),
  className = '',
}) {
  return (
    <input
      className={`${className} btn btn-${intent} ${block ? 'btn-block' : ''}`}
      disabled={disabled}
      type='submit'
      value={value}
    />
  )
}
