// Submit note form on blur in shipments#index
$('.item_note_form').on('blur', "input[name='shipment[notes]']", function() {
  const $form = $(this).parent()
  Rails.fire($form[0], 'submit')
})

// Collapse shipment
document.querySelectorAll(`.js-shipment-ujs-result-collapse`).forEach((el) => {
  el.addEventListener(`click`, (e) => {
    e.preventDefault()
    const id = e.target.getAttribute(`data-id`)
    document.querySelectorAll(`#js-shipment-ujs-result-${id}`)[0].innerHTML = ``
    document.querySelectorAll(
      `#js-shipment-ujs-button-${id}`
    )[0].style.display = `inline-block`
    document.querySelectorAll(
      `#js-shipment-ujs-collapse-${id}`
    )[0].style.display = `none`
  })
})
