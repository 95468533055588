// Takes all data attributes off the target DOM element,
// parses JSON when needed, and returns a proper JS object

export default function parseProps(target) {
  let props = {}
  Object.keys(target.dataset).forEach((data) => {
    const val = target.dataset[data]
    props[data] = val.charAt(0) === '{' ? JSON.parse(val) : val
  })
  return props
}
