import React from 'react'

export default function Justify({
  vertical = false,
  centered = false,
  children = <div />,
}) {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: centered ? 'space-around' : 'space-between',
        flexDirection: vertical ? 'column' : 'row',
      }}
    >
      {children}
    </div>
  )
}
