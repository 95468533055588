// ~ HeRe Be DRaGONs ~
// This file is for code that runs 👹 Globally on window, like Bootstrap, jQuery, and UJS
// This needs to be migrated later, please do not add to it unless supporting legacy jQuery 💘
//
//                               #########
//                     ## ##   ########## #####
//                  ########     #########        #
//                ############       ##########   ####
//             # # ###     #####   ######  ###    ########
//              ##        ############      ######### #####
//        ## ###### ##    ########################      #####
//       ###############  ####### ####################   #####
//      #####      #############    #####################  #####
//     ######         ##########     ######################## ###
//    ### #      ##  ##########################################
//             ####### ##########################################
//    ## #######  ####   ########################################
//    #####  ####  ##### #######################################
//    ####     ### ###### ######################################
//     ###########        ######## ################  ###########
//     ### ### #      ##      ###  ################# ###########
//      ###         ###### ###      ######  ###################
//       ###         ######         ######  ########  ########
//        ####      ####################  #######   #######
//           ###          ###             ######  ########
//                #####             #########   ######
//                   #####################  #######
//                             #   ########
//

import $ from 'jquery'
import 'jquery-color'
import bootbox from 'bootbox'
import '../styles/legacy.scss'

window.$ = $ // 👹 Globals: $
window.jQuery = $ // 👹 Globals: jQuery
window.bootbox = bootbox // 👹 Globals: bootbox

require('bootstrap') // 👹 Globals: $.popover() $.modal()
require('select2') // 👹 Globals: $.select2()
require('bootstrap-datepicker') // 👹 Globals: $.datepicker()

export default function legacy() {
  require('../legacy/dropdown')
  require('../legacy/onboarding')
  require('../legacy/mvb')
  require('../legacy/popovers') // 👹 Globals: select2loadCustom()
  require('../legacy/shipments')
  require('../legacy/affiliate')
  require('../legacy/consolidations')
  require('../legacy/current_parcel')
  require('../legacy/destination_addresses')
  require('../legacy/fast_buy')
  require('../legacy/optional_line_items')
  require('../legacy/photoswipe')
  require('../legacy/registrations')
  require('../legacy/service_requests')
  require('../legacy/users')
  require('../legacy/client_side_validations_input_group')
}
