import t from '../functions/t'

// Addons (options K and x are mutually exclusive)
$('.js-current-parcel-item').on('change', 'form', ({ target }) => {
  const $target = $(target)
  const $form = $target.closest('form')
  if ($target.is(':checked') && $target.data('addon-type') === 'K') {
    bootbox.confirm({
      message: t('warnings.original_package'),
      callback(result) {
        $target.prop('checked', result)
        if (result) {
          $form.find("input[data-addon-type='x']").prop('checked', false)
          Rails.fire($form[0], 'submit')
        }
      },
    })
  } else if ($target.is(':checked') && $target.data('addon-type') === 'x') {
    $form.find("input[data-addon-type='K']").prop('checked', false)
    Rails.fire($form[0], 'submit')
  } else {
    Rails.fire($form[0], 'submit')
  }
})

// Content Type (Not in use)
$('#content_type_form').on('change', 'form', ({ target }) => {
  const $form = $(target).closest('form')
  Rails.fire($form[0], 'submit')
})

// Address & Preferred carrier
$('#current_parcel_shipping_info').on('change', 'form', ({ target }) => {
  const $form = $(target).closest('form')
  Rails.fire($form[0], 'submit')
})

// Address Search
$('.js-action-current-parcel-address-filter').on('click', () => {
  $('#address-search-form-current-parcel').toggle()
})

// Acknowledge all warnings to enable Submit
$('#current_parcel_confirmation_button').on(
  'click',
  '#js-acknowledge-blocker',
  (e) => {
    const $form = $(e.target).closest('form')
    const $acknowledgements = $('#current_parcel_confirmation_button').find(
      '.js-acknowledge'
    )

    const isAcknowledged = []

    $acknowledgements.each((i, val) => {
      if ($(val).is(':checked')) {
        isAcknowledged.push(val)
        $(val).removeClass('is-invalid')
        $(val)
          .siblings()
          .removeClass('is-invalid')
      } else {
        $(val).addClass('is-invalid')
        $(val)
          .siblings()
          .addClass('is-invalid')
      }
    })

    if (isAcknowledged.length !== $acknowledgements.length) {
      e.preventDefault()
    }
  }
)

$('#current_parcel_preferred_carrier_weight').on('click', () => {
  $('#current_parcel_preferred_carrier_custom_weight').prop('checked', true)
})

$('#current_parcel_preferred_carrier_weight').on('change', ({ target }) => {
  const $form = $(target).closest('form')
  Rails.fire($form[0], 'submit')
  let weight = $('.js-preferred-carrier-weight').val()
  let weight_kg = parseFloat(weight)
  let weight_lbs = (weight_kg / 2.2046).toFixed(1)
  if (!weight) {
    $('.custom-weight-conversion').html(`lb`)
  } else {
    $('.custom-weight-conversion').html(`lb (${weight_lbs} kg)`)
  }
})

$('.js-preferred-carrier-selection').on('change', ({ target }) => {
  const id = target.id
  if (id != 'current_parcel_preferred_carrier_custom_weight') {
    $('.js-preferred-carrier-weight').val('')
    $('.custom-weight-conversion').html(`lb`)
  }
})

// Show or hide insurance warning
$('input.js-insurance-radio[type=radio]').change(function() {
  if (this.value == 'No') {
    $('.js-insurance-no').show()
  } else {
    $('.js-insurance-no').hide()
  }
})
