import React from 'react'

export default function Col({
  className = '',
  xs = 0,
  sm = 0,
  md = 0,
  lg = 0,
  xl = 0,
  xsOffset = 0,
  smOffset = 0,
  mdOffset = 0,
  lgOffset = 0,
  xlOffset = 0,
  xsOrder = 0,
  smOrder = 0,
  mdOrder = 0,
  lgOrder = 0,
  xlOrder = 0,
  ...props
}) {
  return (
    <div
      {...props}
      className={
        className +
        (xs !== 0 ? ` col-${xs}` : ` col-12`) +
        (sm !== 0 ? ` col-sm-${sm}` : '') +
        (md !== 0 ? ` col-md-${md}` : '') +
        (lg !== 0 ? ` col-lg-${lg}` : '') +
        (xl !== 0 ? ` col-xl-${xl}` : '') +
        (xsOffset !== 0 ? ` offset-${xsOffset}` : '') +
        (smOffset !== 0 ? ` offset-sm-${smOffset}` : '') +
        (mdOffset !== 0 ? ` offset-md-${mdOffset}` : '') +
        (lgOffset !== 0 ? ` offset-lg-${lgOffset}` : '') +
        (xlOffset !== 0 ? ` offset-xl-${xlOffset}` : '') +
        (xsOrder !== 0 ? ` order-${xsOrder}` : '') +
        (smOrder !== 0 ? ` order-sm-${smOrder}` : '') +
        (mdOrder !== 0 ? ` order-md-${mdOrder}` : '') +
        (lgOrder !== 0 ? ` order-lg-${lgOrder}` : '') +
        (xlOrder !== 0 ? ` order-xl-${xlOrder}` : '')
      }
    />
  )
}
