/**
 * Object of translations. Preprocessed by Webpack.
 * List of translation keys for frontend can be found in config/webpack/translations.js
 */
let TRANSLATIONS = { actions: { save: { en: 'Save' } } }

// @ts-ignore
if (typeof __INJECTED_TRANSLATIONS_DEFINED__ !== 'undefined') {
  // X_INJECTED_TRANSLATIONS_X (without 'X's) is directly replaced by a very large translation object during the webpack build process. This is why the actual name isn't written in this comment, as it would inject it twice. This is why you shouldn't compare directly against it for 'defined?', use X_INJECTED_TRANSLATIONS_DEFINED_X for that.
  // @ts-ignore
  TRANSLATIONS = __INJECTED_TRANSLATIONS__
}

export default TRANSLATIONS
