import TRANSLATIONS from '../constants/TRANSLATIONS'

/**
 * Get document language, default to 'en'
 */
const locale = document.documentElement.lang
  ? document.documentElement.lang
  : 'en'

/**
 * Print translation
 * @param {string} key translation key, e.g. 'actions.save'
 * @param {string} lang language code, e.g. 'en'
 */
export default function t(key = '', lang = locale) {
  let obj = TRANSLATIONS
  key.split('.').forEach((keyPart) => {
    obj = obj[keyPart] ? obj[keyPart] : undefined
  })

  if (obj && obj[lang]) {
    return obj[lang]
  } else {
    if (process.env.NODE_ENV === 'development') {
      console.warn(`Missing translation: ${key}`)
    }
    return '░░░░░'
  }
}
