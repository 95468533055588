import anime from 'animejs'

export default function animateStores() {
  anime({
    targets: document.getElementById('logo_0'),
    opacity: [{ value: 1 }, { value: 0.2 }, { value: 1 }],
    duration: 4000,
    loop: true,
    easing: 'linear',
  })

  anime({
    targets: document.getElementById('logo_1'),
    opacity: [{ value: 1 }, { value: 0.2 }, { value: 1 }],
    duration: 4200,
    loop: true,
    easing: 'linear',
  })

  anime({
    targets: document.getElementById('logo_2'),
    opacity: [{ value: 1 }, { value: 0.2 }, { value: 1 }],
    duration: 4400,
    loop: true,
    easing: 'linear',
  })

  anime({
    targets: document.getElementById('logo_3'),
    opacity: [{ value: 1 }, { value: 0.2 }, { value: 1 }],
    duration: 4600,
    loop: true,
    easing: 'linear',
  })

  anime({
    targets: document.getElementById('logo_4'),
    opacity: [{ value: 1 }, { value: 0.2 }, { value: 1 }],
    duration: 4800,
    loop: true,
    easing: 'linear',
  })

  anime({
    targets: document.getElementById('logo_5'),
    opacity: [{ value: 1 }, { value: 0.2 }, { value: 1 }],
    duration: 5000,
    loop: true,
    easing: 'linear',
  })

  anime({
    targets: document.getElementById('logo_6'),
    opacity: [{ value: 1 }, { value: 0.2 }, { value: 1 }],
    duration: 5200,
    loop: true,
    easing: 'linear',
  })

  anime({
    targets: document.getElementById('logo_7'),
    opacity: [{ value: 1 }, { value: 0.2 }, { value: 1 }],
    duration: 5400,
    loop: true,
    easing: 'linear',
  })

  anime({
    targets: document.getElementById('logo_8'),
    opacity: [{ value: 1 }, { value: 0.2 }, { value: 1 }],
    duration: 5600,
    loop: true,
    easing: 'linear',
  })

  anime({
    targets: document.getElementById('logo_9'),
    opacity: [{ value: 1 }, { value: 0.2 }, { value: 1 }],
    duration: 5800,
    loop: true,
    easing: 'linear',
  })

  anime({
    targets: document.getElementById('logo_10'),
    opacity: [{ value: 1 }, { value: 0.2 }, { value: 1 }],
    duration: 6000,
    loop: true,
    easing: 'linear',
  })

  anime({
    targets: document.getElementById('logo_11'),
    opacity: [{ value: 1 }, { value: 0.2 }, { value: 1 }],
    duration: 6200,
    loop: true,
    easing: 'linear',
  })

  anime({
    targets: document.getElementById('logo_12'),
    opacity: [{ value: 1 }, { value: 0.2 }, { value: 1 }],
    duration: 6400,
    loop: true,
    easing: 'linear',
  })
}
